import { googleMapsUrl } from "./generalUtils";

export const socials = {
	facebook: "https://www.facebook.com/profile.php?id=61555207977085",
	//	twitter: "https://twitter.com/your-twitter-page",
	instagram: "https://www.instagram.com/pizzeriasmogen",
	//	linkedin: "https://www.linkedin.com/in/your-linkedin-page",
	//tiktok: "https://www.tiktok.com/@your-tiktok-page",
	//youtube: "https://www.youtube.com/channel/your-youtube-page",
	//pinterest: "https://www.pinterest.com/your-pinterest-page",
	google: googleMapsUrl,
};
