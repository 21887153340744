import { createTheme } from "@mui/material/styles";

export const buttonColor = "#b99272";

const theme = createTheme({
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: "#090C0F",
				},
			},
		},
	},
	typography: {
		fontFamily: "Roboto, sans-serif",
	},
	palette: {
		primary: {
			main: "#090C0F",
		},
		secondary: {
			main: "#0E1317",
		},
	},
});

export default theme;
