import React, { Suspense, useEffect } from "react";
import "../css/App.css";
import { CustomLoader } from "../minis/CustomLoader";
import Navbar from "./navbar/Navbar";
import ScrollToTop from "../minis/ScrollToTop";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../theme";
import ImageSlider from "./slider/ImageSlider";
import slider1 from "../resources/main-slider_bg-1.jpg";
import {
	LazyAboutPage,
	LazyCatergoryCard,
	LazyContactPage,
	LazyCustomerFeedback,
	LazyFooter,
	LazyMenuPage,
	LazyPopularMenu,
} from "./LazyLoadImports";
import "../i18n";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { restaurantName } from "../utils/generalUtils";
import { useTranslation } from "react-i18next";

const HomePage = () => {
	const { t } = useTranslation();
	return (
		<Box sx={{ backgroundColor: theme.palette.secondary.main + "cc" }}>
			<ImageSlider
				backgroundImage={slider1}
				title={restaurantName.toUpperCase()}
				text1={t("general.slogan1")}
				text2={t("general.slogan2")}
				showButtons
				height={850}
				textPosition="80vh"
				mobileTextPosition="100vh"
			/>
			<LazyPopularMenu showButton />
			<LazyCatergoryCard />
			<LazyCustomerFeedback />
		</Box>
	);
};

const ScrollToTopOnNavigation = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
};

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<ScrollToTopOnNavigation />
				<ScrollToTop />
				<Navbar />
				<Suspense fallback={<CustomLoader />}>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/menu" element={<LazyMenuPage />} />
						<Route path="/about" element={<LazyAboutPage />} />
						<Route path="/contact" element={<LazyContactPage />} />
					</Routes>
					<LazyFooter />
				</Suspense>
			</Router>
		</ThemeProvider>
	);
};

export default App;
