import React from "react";
import { AppBar, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import NavigationMenu from "./NavigationMenu";
import LogoWithName from "../../minis/LogoWithName";
import CallButton from "../../minis/CallButton";
import PhoneNavbar from "./phone/PhoneNavbar";
import { navbarSx } from "./navbar.styles";
import SmallNavbar from "./SmallNavbar";

const Navbar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	if (isMobile) return <PhoneNavbar />;

	return (
		<>
			<SmallNavbar />
			<AppBar position="sticky" sx={{ ...navbarSx }}>
				<Toolbar sx={{ justifyContent: "space-between", pt: 2, pb: 2 }}>
					<LogoWithName />
					<NavigationMenu />
					<CallButton />
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Navbar;
