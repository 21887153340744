import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Drawer, List, Divider, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoWithName from "../../../minis/LogoWithName";
import SocialButtons from "../../../minis/SocialButtons";
import CallButton from "../../../minis/CallButton";
import OpeningHours from "../../../minis/OpeningHours";
import RestaurantAddress from "../../../minis/RestaurantAddress";
import CloseIcon from "@mui/icons-material/Close";
import { appBarSx, boxSx, drawerSx, toolbarSx } from "../navbar.styles";
import { drawerSlideProps } from "../navbarUtils";
import PhoneNavigationMenu from "./PhoneNavigationMenu";
import CenterBox from "../../../helpers/CenterBox";

const PhoneNavbar = () => {
	const [isMenuOpen, setMenuOpen] = useState(false);

	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<>
			<AppBar position="fixed" sx={appBarSx}>
				<Toolbar sx={toolbarSx}>
					<LogoWithName />
					<IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setMenuOpen(!isMenuOpen)}>
						<MenuIcon fontSize="large" />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer
				anchor="top"
				open={isMenuOpen}
				onClose={() => setMenuOpen(!isMenuOpen)}
				SlideProps={drawerSlideProps}
				sx={drawerSx}
			>
				<List>
					<Box sx={boxSx}>
						<LogoWithName />
						<IconButton
							edge="end"
							sx={{ mr: 1 }}
							color="inherit"
							aria-label="close"
							onClick={() => setMenuOpen(!isMenuOpen)}
						>
							<CloseIcon fontSize="large" />
						</IconButton>
					</Box>
					<Box p={2.5}>
						<PhoneNavigationMenu closeMenu={closeMenu} />
					</Box>
					<Divider variant="middle" sx={{ backgroundColor: "white", mb: 3 }} />
					<CenterBox>
						<CallButton />
					</CenterBox>
					<Box p={2}>
						<OpeningHours />
						<RestaurantAddress />
					</Box>
					<CenterBox>
						<SocialButtons />
					</CenterBox>
				</List>
			</Drawer>
		</>
	);
};

export default PhoneNavbar;
