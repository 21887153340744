import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../src/resources/translations/en.json";
import sv from "../src/resources/translations/sv.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { translation: en },
			sv: { translation: sv },
		},
		fallbackLng: {
			"en-US": ["en", "sv"],
			default: ["sv", "en"],
		},
		interpolation: {
			escapeValue: false,
		},
		detection: {
			order: ["navigator", "querystring", "cookie", "localStorage", "htmlTag"],
			caches: ["localStorage", "cookie"],
		},
	});

export default i18n;
