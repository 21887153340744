import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

interface SliderTextProps {
	text1?: string;
	text2?: string;
}

const SliderText = ({ text1, text2 }: SliderTextProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<Typography
				variant={isMobile ? "h4" : "h2"}
				sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}
				className="fadeInLeftToRight"
			>
				{text1}
			</Typography>
			<Typography
				variant={isMobile ? "h4" : "h2"}
				mb={4}
				sx={{ fontWeight: "bold", textAlign: "left", width: "100%" }}
				className="fadeInLeftToRight"
			>
				{text2}
			</Typography>
		</>
	);
};

export default SliderText;
