import React from "react";
import { AppBar, Toolbar } from "@mui/material";
import SocialButtons from "../../minis/SocialButtons";
import OpeningHours from "../../minis/OpeningHours";
import RestaurantAddress from "../../minis/RestaurantAddress";
import { smallNavbarSx } from "./navbar.styles";

const SmallNavbar = () => {
	return (
		<AppBar position="static" sx={smallNavbarSx}>
			<Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
				<OpeningHours />
				<SocialButtons />
				<RestaurantAddress />
			</Toolbar>
		</AppBar>
	);
};

export default SmallNavbar;
