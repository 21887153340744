import React from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { restaurantName } from "../utils/generalUtils";
import Logo from "./Logo";

interface LogoWithNameProps {
	color?: string;
	weight?: "bold" | "normal" | "lighter";
}

const LogoWithName = ({ color, weight }: LogoWithNameProps) => {
	const navigate = useNavigate();

	return (
		<Stack direction="row" alignItems="center" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
			<Logo />
			<Typography color={color} fontWeight={weight} ml={0.5} variant="h5">
				{restaurantName}
			</Typography>
		</Stack>
	);
};

export default LogoWithName;
