import { MouseEvent } from "react";
import { Fab, Zoom, useScrollTrigger } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useEventListener } from "usehooks-ts";
import { isKeyboardEvent } from "../helpers/typeGuard";
import React from "react";
import { buttonColor } from "../theme";

interface ScrollToTopProps {
	threshold?: number;
}

const handleOnClick = (event: MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
	if (isKeyboardEvent(event) && event.key !== "ArrowUp") return;

	window.scrollTo({ top: 0, behavior: "smooth" });
};

const ScrollToTop = ({ threshold = 300 }: ScrollToTopProps) => {
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold,
	});

	useEventListener("keydown", handleOnClick);

	return (
		<Zoom in={trigger}>
			<Fab
				sx={{ backgroundColor: buttonColor, color: "white" }}
				size="small"
				onClick={handleOnClick}
				style={{ position: "fixed", bottom: 16, right: 16 }}
			>
				<ArrowUpwardIcon />
			</Fab>
		</Zoom>
	);
};

export default ScrollToTop;
