import React from "react";
import { Button } from "@mui/material";
import { phoneNavigationSx } from "../navbar.styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PhoneNavigationMenu = ({ closeMenu }) => {
	const { t } = useTranslation();

	return (
		<>
			<Link to="/" onClick={closeMenu} style={{ textDecoration: "none" }}>
				<Button sx={phoneNavigationSx}>{t("navigation.home")}</Button>
			</Link>
			<Link to="/menu" onClick={closeMenu} style={{ textDecoration: "none" }}>
				<Button sx={phoneNavigationSx}>{t("navigation.menu")}</Button>
			</Link>
			<Link to="/about" onClick={closeMenu} style={{ textDecoration: "none" }}>
				<Button sx={phoneNavigationSx}>{t("navigation.about")}</Button>
			</Link>
			<Link to="/contact" onClick={closeMenu} style={{ textDecoration: "none" }}>
				<Button sx={phoneNavigationSx}>{t("navigation.contact")}</Button>
			</Link>
		</>
	);
};

export default PhoneNavigationMenu;
