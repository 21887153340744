import React from "react";
import { Button, Stack } from "@mui/material";
import theme from "../theme";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const buttonSx = {
	backgroundColor: theme.palette.primary.main,
	color: "white",
	textTransform: "uppercase",
	fontWeight: "bold",
	fontSize: "14px",
	padding: "15px 25px",
	borderRadius: "10px",
	width: "150px",
	transition: "background-color 0.4s ease, color 0.4s ease",
	"&:hover": {
		backgroundColor: "white",
		color: theme.palette.secondary.main,
	},
};

const MenuButton = () => {
	const { t } = useTranslation();
	return (
		<Stack direction="row" alignItems="center">
			<Link to="/menu" style={{ textDecoration: "none" }}>
				<Button size="small" sx={buttonSx} endIcon={<ArrowForwardIosIcon />}>
					<RestaurantMenuIcon sx={{ mr: 1 }} />
					{t("navigation.menu")}
				</Button>
			</Link>
		</Stack>
	);
};

export default MenuButton;
