import React from "react";
import { IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Facebook, Instagram, Google } from "@mui/icons-material";
import { socials } from "../utils/socials";

const SocialButtons = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Stack direction="row" spacing={isMobile ? 2 : 0} sx={{ ml: !isMobile ? "146px" : "0" }}>
			<IconButton size={"large"} color="inherit" onClick={() => window.open(socials.facebook, "_blank")}>
				<Facebook fontSize="small" />
			</IconButton>
			<IconButton size="large" color="inherit" onClick={() => window.open(socials.google, "_blank")}>
				<Google fontSize="small" />
			</IconButton>
			<IconButton size="large" color="inherit" onClick={() => window.open(socials.instagram, "_blank")}>
				<Instagram fontSize="small" />
			</IconButton>
		</Stack>
	);
};

export default SocialButtons;
