import { SxProps, Theme } from "@mui/material";
import theme, { buttonColor } from "../../theme";

export const menuButtonSx = {
	color: "white",
	lineHeight: "40px",
	letterSpacing: "0.05em",
	fontWeight: "bold",
	"&:hover": {
		backgroundColor: "transparent",
		color: buttonColor,
	},
	marginRight: "24px",
};

export const smallNavbarSx = {
	background: theme.palette.primary.main,
	color: "white",
	zIndex: 1,
};

export const navbarSx = {
	background: theme.palette.secondary.main,
	color: "white",
};

export const appBarSx: SxProps<Theme> = {
	background: theme.palette.secondary.main,
	color: "white",
	pt: 2,
	pb: 2,
	pr: 2,
};

export const toolbarSx: SxProps<Theme> = {
	justifyContent: "space-between",
};

export const drawerSx: SxProps<Theme> = {
	"& .MuiDrawer-paper": {
		backgroundColor: theme.palette.secondary.main,
		color: "white",
		paddingTop: "env(safe-area-inset-top)",
	},
};

export const boxSx: SxProps<Theme> = {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
};

export const typographySx: SxProps<Theme> = {
	fontWeight: "bold",
	fontSize: "1.2em",
};

export const phoneNavigationSx: SxProps<Theme> = {
	display: "flex",
	flexDirection: "column",
	fontWeight: "bold",
	color: "white",
	fontSize: "1.4em",
};
