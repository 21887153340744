import React from "react";
import { Box, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { restaurantLocation } from "../utils/generalUtils";
import { buttonColor } from "../theme";
import { useTranslation } from "react-i18next";

const RestaurantAddress = () => {
	// TODO: Fix alignment of icon and text on phone navbar
	const { t } = useTranslation();
	return (
		<Box display="flex" alignItems="center">
			<LocationOnIcon fontSize="small" sx={{ color: buttonColor, mr: 0.5 }} />
			<Typography fontSize="13px" variant="caption" sx={{ color: buttonColor, mr: 0.5 }}>
				{t("general.location")}
			</Typography>
			<Typography fontSize="13px" variant="caption">
				{restaurantLocation.toUpperCase()}
			</Typography>
		</Box>
	);
};

export default RestaurantAddress;
