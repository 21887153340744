import React from "react";
import { Grid, Box } from "@mui/material";
import LogoWithName from "./LogoWithName";
import { buttonColor } from "../theme";
import CenterBox from "../helpers/CenterBox";

export const CustomLoader = () => {
	return (
		<CenterBox sx={{ height: "100vh" }}>
			<Grid container color={"white"} width={"250px"}>
				<LogoWithName />
				<Box
					sx={{
						position: "relative",
						width: "100%",
						height: "2px",
					}}
				>
					<Box
						sx={{
							position: "absolute",
							width: "60%",
							height: "100%",
							backgroundColor: buttonColor,
							animation: "loadingBar 1.2s linear infinite",
							"@keyframes loadingBar": {
								"0%": { transform: "translateX(-100%)", opacity: 0 },
								"10%": { opacity: 1 },
								"90%": { opacity: 1 },
								"100%": { transform: "translateX(100%)", opacity: 0 },
							},
						}}
					/>
				</Box>
			</Grid>
		</CenterBox>
	);
};

export default CustomLoader;
