// General
export const restaurantLocation = "Hasselösundsvägen 5, 456 50 Smögen";
export const restaurantName = "Pizzeria Smögen";
export const openingHours = " 11:00 - 22:00";

// Footer details
export const restaurantPhone = "0523-300 92";
export const restaurantEmail = "Mirdan_reked@hotmail.com";
export const mondayToFridayOpenHours = "11:00 - 22:00";
export const saturdayOpenHours = "11:00 - 22:00";
export const sundayOpenHours = "11:00 - 22:00";
export const googleMapsUrl =
	"https://www.google.com/maps/place/Pizzeria+Sm%C3%B6gen/@58.3623451,11.2312876,15z/data=!4m2!3m1!1s0x0:0x3d9017ab999eca8b?sa=X&ved=2ahUKEwiq087f8bCDAxVZGxAIHU5KCbwQ_BJ6BAgLEAA";

// About Us
export const imbeddedYoutubeVideo = "U0R23RKNmpI";
