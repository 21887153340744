import React from "react";
import { Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { openingHours } from "../utils/generalUtils";
import { buttonColor } from "../theme";
import { useTranslation } from "react-i18next";

const OpeningHours = () => {
	const { t } = useTranslation();
	return (
		<Stack direction="row" alignItems="center" gap={0.5}>
			<AccessTimeIcon fontSize="small" sx={{ color: buttonColor }} />
			<Typography fontSize="13px" variant="caption" sx={{ color: buttonColor }}>
				{t("general.openHours")}
			</Typography>
			<Typography fontSize="13px" variant="caption">
				{openingHours}
			</Typography>
		</Stack>
	);
};

export default OpeningHours;
