import React from "react";
import { Button, Stack } from "@mui/material";
import { buttonColor } from "../theme";
import { restaurantPhone } from "../utils/generalUtils";
import PhoneOutlined from "@mui/icons-material/PhoneOutlined";
import { useTranslation } from "react-i18next";

export const callButtonSx = {
	backgroundColor: buttonColor,
	color: "white",
	textTransform: "uppercase",
	fontWeight: "bold",
	fontSize: "14px",
	padding: "15px 25px",
	width: "150px",
	borderRadius: "10px",
	transition: "background-color 0.4s ease, color 0.4s ease",
	"&:hover": {
		backgroundColor: "white",
		color: buttonColor,
	},
};

const CallButton = () => {
	const { t } = useTranslation();
	return (
		<Stack direction="row" alignItems="center">
			<Button sx={callButtonSx} href={`tel:${restaurantPhone}`}>
				<PhoneOutlined sx={{ mr: 1 }} />
				{t("general.order")}
			</Button>
		</Stack>
	);
};

export default CallButton;
