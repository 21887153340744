import React from "react";
import { Stack } from "@mui/material";
import NavigationButton from "./NavigationButton";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavigationMenu = () => {
	const { t } = useTranslation();

	return (
		<Stack direction="row" mr={10}>
			<Link to="/" style={{ textDecoration: "none" }}>
				<NavigationButton>{t("navigation.home")}</NavigationButton>
			</Link>
			<Link to="/menu" style={{ textDecoration: "none" }}>
				<NavigationButton>{t("navigation.menu")}</NavigationButton>
			</Link>
			<Link to="/about" style={{ textDecoration: "none" }}>
				<NavigationButton>{t("navigation.about")}</NavigationButton>
			</Link>
			<Link to="/contact" style={{ textDecoration: "none" }}>
				<NavigationButton>{t("navigation.contact")}</NavigationButton>
			</Link>
		</Stack>
	);
};

export default NavigationMenu;
