import React from "react";
import { Box, Typography, Paper, useTheme, useMediaQuery, Container } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CallButton from "../../minis/CallButton";
import MenuButton from "../../minis/MenuButton";
import SliderText from "./SliderText";
import { buttonColor } from "../../theme";

interface ImageSliderProps {
	backgroundImage: string;
	title?: string;
	showButtons?: boolean;
	text1?: string;
	text2?: string;
	height?: number;
	textPosition?: string;
	mobileTextPosition?: string;
}

const ImageSlider = ({
	backgroundImage,
	title,
	showButtons = false,
	text1,
	text2,
	height = 440,
	textPosition = "50vh",
	mobileTextPosition = "75vh",
}: ImageSliderProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Paper
			sx={{
				position: "relative",
				height: height,
				backgroundSize: "cover",
				backgroundPosition: "center",
				backgroundImage: `url(${backgroundImage})`,
				color: "white",
				paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(10),
				backgroundColor: "inherit",
			}}
		>
			<Container
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					height: isMobile ? mobileTextPosition : textPosition,
				}}
			>
				{title && (
					<Box style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
						<HorizontalRuleIcon sx={{ color: buttonColor }} />
						<HorizontalRuleIcon sx={{ color: buttonColor, marginLeft: -1, mr: 2 }} />
						<Typography>{title}</Typography>
					</Box>
				)}
				<SliderText text1={text1} text2={text2} />
				{showButtons && (
					<Box sx={{ alignSelf: "flex-start", display: "flex", gap: 1.5 }}>
						<CallButton />
						<MenuButton />
					</Box>
				)}
			</Container>
		</Paper>
	);
};

export default ImageSlider;
